$light: #fff;
$dark: #000;

@font-face {
  font-family: 'Lumberjack';
  src: url('/static/fonts/lumberjack.otf');
  font-weight: normal;
  font-style: normal;
}

.brand-logo {
  display: block;
  height: 100%;
  img {
    height: 5.625em;
  }
}

.countdown-wrapper {
  &__light {
    color: $light;
    border-color: $light;
  }
  &__dark {
    color: $dark;
    border-color: $dark;
  }
}

.countdown-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: .3125em 2.5em;
  .headline {
    flex: 1 0 100%;
    margin: 0 0 .625em;
    font-size: 1.125em;
    // text-transform: uppercase;
  }
}


.countdown-timer {
  display: flex;
}

.value {
  font-family: Lumberjack;
  font-size: 2.5em;
  line-height: 1em;
}
.icon {
  display: block;
  height: .5em;
  line-height: 0;
}
.unit {
  padding: 0 .1875em;
  font-size: 1em;
  // text-transform: uppercase;
  white-space: nowrap;
}
.part {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 1em;

  border-right: .125em solid currentColor;
}

.part .unit {
  min-width: 3.125em;
}

@media (min-width: 768px) {
  .countdown-block {
    position: relative;
    z-index: 10;
  }
}
